import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import css from 'styled-jsx/css';

import Markdown from '../components/Markdown';
import Document from '../components/Document';
import Banner from '../components/Banner';
import Section from '../components/Section';
import Column from '../components/Column';
import LinkButton from '../components/LinkButton';

import { bluishPurple } from '../css-utilities';

const buttonStyle = css.resolve`
  margin: 10px 15px 10px 0px;
`;

const GenericPage = ({
  data: {
    datoCmsGenericPage: {
      seo,
      bannerImage,
      bannerPunchline,
      bannerHeader,
      headerPrefix,
      header,
      content,
      showButtons,
      buttons,
      showBanner,
    },
  },
}) => {
  const generatedButtons = buttons.map((button, i) => (
    <LinkButton
      arrowDirection="right"
      className={buttonStyle.className}
      dark={i === 0 ? true : false}
      key={button.id}
      label={button.title}
      to={button.path}
    />
  ));

  return (
    <Document seo={seo}>
      {showBanner && (
        <Banner
          bgImage={bannerImage}
          header={bannerHeader}
          punchline={bannerPunchline}
        />
      )}
      <Section>
        <Column>
          {headerPrefix && <span className="prefix">{headerPrefix}</span>}
          {header && <h1>{header}</h1>}

          {content && <Markdown source={content} />}

          <div className="buttons">{showButtons && generatedButtons}</div>
        </Column>
      </Section>

      {buttonStyle.styles}

      <style jsx>
        {`
          .prefix {
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: bold;
            color: ${bluishPurple};
          }
          .buttons {
            margin-top: 20px;
          }
        `}
      </style>
    </Document>
  );
};

export const query = graphql`
  query genericPageQuery($pageId: String!) {
    datoCmsGenericPage(id: { eq: $pageId }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      showBanner
      bannerHeader
      bannerPunchline
      bannerImage {
        url
      }

      headerPrefix
      header

      content

      showButtons
      buttons {
        id
        title
        path
      }
    }
  }
`;

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericPage;
